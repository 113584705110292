<template>
  <div>
    <KTCodePreview v-bind:title="'In biên bản kiểm kê'">
      <template v-slot:toolbar>
        <b-button variant="primary" size="sm" class="font-weight-bolder" @click="onClickToPrint(printMode)">
          <i style="font-size: 1rem" class="fas fa-print"></i>In biên bản kiểm kê tồn kho
        </b-button>
      </template>
      <template v-slot:preview>
        <hr class="hr-text" data-content="Khổ giấy A4- A5" style="font-weight: 600" />
        <div id="A4">
          <PrintInventoryCheckComponent :printData="mainModel" />
        </div>
        <br />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import PrintInventoryCheckComponent from '@/view/components/inventoryCheck/PrintInventoryCheckComponent.vue';
import { TIME_TRIGGER } from '@/utils/constants';
import saveData from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
    PrintInventoryCheckComponent,
  },
  data() {
    return {
      mainModel: {
        productStockCheck: null,
        productItems: [],
        stockDebtItems: [],
        payments: [],
      },
      printMode: 'A4',
      renderComponent: false,

    };
  },
  created() {
    let defaultPrintMode = saveData.getData('defaultPrintMode');
    if (!defaultPrintMode) {
      defaultPrintMode = 'A4';
      saveData.saveData('defaultPrintMode', this.printMode);
    }
    this.printMode = defaultPrintMode;
    const productStockCheckId = this.$route.query.id;
    this.getStockCheckById(productStockCheckId);
    this.getProductStockCheckById(productStockCheckId);
   
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kiểm tồn kho', route: '/inventory-check' },
      { title: 'In phiếu truy thu tồn kho' },
    ]);
},
methods: {
  getStockCheckById(id) {
    ApiService.get(`/stockDebt/${id}`).then((response) => {
      const {
        StockDebtItems,
        payments,
      } = response.data.data;
      this.mainModel.stockDebtItems = StockDebtItems || [];
      this.mainModel.payments = payments;

    });
  },
  getProductStockCheckById(id) {
    const params = {
      id: Number(id),
    };
    ApiService.query('productStockCheck/getProductStockCheckItems', {
      params,
    }).then(({ data }) => {
      this.mainModel.productStockCheck = data.data.detail;
      this.mainModel.productItems = data.data.items;
    });
  },
  calcTotalPay(data) {
    const sum = [
      'depositAmount',
      'transferFromOrder',
      'moneyCredit',
      'moneyInstallment',
      'pointToMoney',
    ].reduce((result, item) => {
      const amount = data[item] || 0;
      return result + amount;
    }, 0);
    const totalPay = data.totalAmount - sum + data.customerShipFee;

    return totalPay;
  },
  upperCaseFirstLetter(text) {
    return text.charAt(1).toUpperCase() + text.slice(2).toLowerCase();
  },
  printData(mode = 'A4', stylePrint) {
    const prtHtml = document.getElementById(mode).innerHTML;
    let stylesHtml = '';
    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
      stylesHtml += node.outerHTML;
    }

    // Open the print window
    const WinPrint = window.open(
      '',
      '',
      'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
    );
    WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
          ${stylePrint}
        </head>
        <body`);
    WinPrint.document.write(`${prtHtml}
        </body>
      </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    setTimeout(function () {
      WinPrint.print();
    }, TIME_TRIGGER);
  },
  formatMoney(number) {
    return new Intl.NumberFormat('vn-VN').format(number);
  },
  getStylePrint() {
    return `<style>
                .font-size-print {font-size: 20px !important; color: black;}
                .font-size-gift {font-size: 18px !important; color: black;}
                .font-size-name{ font-size: 20px !important; color: black;}
                .font-size-warranty{ font-size: 26px !important; color: black;}
                .font-size-product-info{ font-size: 20px !important; color: black;}
                .font-size-price{ font-size: 30px !important; font-weight: bold !important;}
                .text-download-app {font-size: 14px !important; font-weight: 550 !important; margin-top: 4px;}
                .item-print {
                    width: 100% !important;
                    margin-left: 0px !important;
                  }
              </style>`;
  },
  onClickToPrint(mode) {
    this.printMode = mode;
    const stylePrint = this.getStylePrint();
    this.printData(mode, stylePrint);
  },
},
};
</script>

